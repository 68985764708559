import { isDebug } from '../helpers/utils';
import log from '../helpers/log';

interface GPTEvent {
  slot: {
    getSlotElementId: () => string;
  };
  advertiserId?: string;
  campaignId?: string;
  creativeId?: string;
  isEmpty?: boolean;
  lineItemId?: string;
  size?: string;
  sourceAgnosticCreativeId?: string;
  sourceAgnosticLineItemId?: string;
  inViewPercentage?: number;
}

const initGptDebugging = () => {
  if (isDebug()) {
    window.googletag.pubads().addEventListener('impressionViewable', (event: GPTEvent) => {
      const slotId = event.slot.getSlotElementId();
      log(`Impression for slot ${slotId} became viewable.`);
    });

    window.googletag.pubads().addEventListener('slotOnload', (event: GPTEvent) => {
      const slotId = event.slot.getSlotElementId();
      log(`Creative iframe for slot ${slotId} has loaded.`);
    });

    window.googletag.pubads().addEventListener('slotRenderEnded', (event: GPTEvent) => {
      const slotId = event.slot.getSlotElementId();
      console.group(`Slot ${slotId} finished rendering.`);
      log('Advertiser ID:', event.advertiserId);
      log('Campaign ID:', event.campaignId);
      log('Creative ID:', event.creativeId);
      log('Is empty?:', event.isEmpty);
      log('Line Item ID:', event.lineItemId);
      log('Size:', event.size);
      log('Source Agnostic Creative ID:', event.sourceAgnosticCreativeId);
      log('Source Agnostic Line Item ID:', event.sourceAgnosticLineItemId);
      console.groupEnd();
    });

    window.googletag.pubads().addEventListener('slotRequested', (event: GPTEvent) => {
      const slotId = event.slot.getSlotElementId();
      log(`Slot ${slotId} has been requested.`);
    });

    window.googletag.pubads().addEventListener('slotResponseReceived', (event: GPTEvent) => {
      const slotId = event.slot.getSlotElementId();
      log(`Ad response for slot ${slotId} received.`);
    });

    window.googletag.pubads().addEventListener('slotVisibilityChanged', (event: GPTEvent) => {
      const slotId = event.slot.getSlotElementId();
      console.group(`Visibility of slot ${slotId} changed.`);
      log(`Visible area: ${event.inViewPercentage}%`);
      console.groupEnd();
    });
  }
};

const initGpt = () => {
  log('initGPT ...');
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  window.googletag.cmd.push(() => {
    window.googletag.pubads().collapseEmptyDivs();
    window.googletag.pubads().disableInitialLoad(); // TODO: Not needed with Google Lazy Load
  });
  window.googletag.cmd.push(initGptDebugging);
  log('initGPT DONE');
};

export default initGpt;
