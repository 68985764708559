import network from '../network/network';
import sessionStorage from './sessionStorage';
import { isOcelotSite, alterSubdomain } from './domain';
import { isDebug, isDebugAds, isNotNativeAd, isNativeAd, adHasExistingContainer } from '../helpers/utils';
import log from '../helpers/log';

const isPage2orMore = (pageNo: number) => pageNo > 1;

const useSessionStorage = isOcelotSite() ? isDebug() : !isDebug();
let pageNumber = 0;

const resolveAdsConfig = async (host: string) => {
  const safeHost = alterSubdomain(host);
  const fallbackUri = `${process.env.FALLBACK_CONFIG_BASE_URL}${safeHost}.json`;
  const resource = `/config/get${safeHost}`;
  let response;
  try {
    if (useSessionStorage) {
      const sessionConf = sessionStorage.get();

      if (sessionConf) {
        log('Got Conf from session storage');
        response = sessionConf;
      } else {
        log('Got no Conf from session storage. Fetching from network.');
        response = await network.get(resource);
      }
    } else {
      response = await network.get(resource);
      log('Fetched Config: ', response);
    }
  } catch (e) {
    console.warn('Error while loading ad configuration. Applying fallback', isDebug() ? e : '');
    response = await network.get(fallbackUri);
  }

  return response;
};

const getModifiedConfig = async (host: string, page: number) => {
  const config = await getConfig(host);
  if (config) {
    return await modifyPlacements(config, page);
  }
};

const getConfig = async (host: string) => {
  try {
    const config = await resolveAdsConfig(host);
    useSessionStorage && sessionStorage.set(config);
    return config;
  } catch (e) {
    console.error('Failed to retrieve ads configuration.');
  }
};

const addExtraPlacements = (config: any, placement: string, processed: any) => {
  log('addExtraPlacements ', placement);

  const ads = [...config.ads];
  placement = `${placement}${placement.includes('intext') ? '_ad' : ''}`;
  const xSlot = ads.find((adSlot: any) => {
    const target = adSlot.target;
    return target.includes(`${placement}`) && (target.includes('-x') || target.includes('_x'));
  });

  let placementDivs: any[] | NodeListOf<Element> = placement ? document.querySelectorAll(`[id^="${placement}"]`) : [];

  if (xSlot && placementDivs.length > 0) {
    for (let no = 0; no < placementDivs.length; no++) {
      const target = placementDivs[no].id;

      let newTarget: String = '';
      let newPlacement = placement;
      let slotExists = false;

      newTarget = target && target.includes(placement) && target.substring(0, target.indexOf('-'));
      newPlacement = `${placement}_X`;
      slotExists = ads.some((adSlot: any) => adSlot.target === `${newTarget}`);

      if (slotExists) {
        // Slot already exists. Will not add;
        continue;
      }

      if (newTarget) {
        log(`Adding target ${newTarget} and placement ${newPlacement} from ${target}. div-id: ${placementDivs[no].id}` );

        ads.push({
          ...xSlot,
          target: newTarget,
          placement: newPlacement,
        });
      } else {
        console.warn(`Couldn't set intext_ad_x for ${target}, on index ${no + 1}`);
      }
    }
  } else {
    log(`${placementDivs.length} ${placement} found, so no ${placement}_x will be set`);
  }
  log('ads after adding extra:', ads);

  return { ...config, ads };
};

const modifyPlacements = (config: any, pageNumber: number) => {
  if (config && config.ads) {
    const ads = config.ads.map((ad: any) => {
      if (ad.placement) {
        if (
          ad.placement.match(/^intext_ad_((([0-9]){1}([0-9]){1})|9{1})/) ||
          (isPage2orMore(pageNumber) && ad.placement.match(/^responsive(s)?_[0-9]{1}/))
        ) {
          const placement = ad.placement.match(/^intext/) ? 'intext_ad' : 'responsive';
          ad.placement = `${placement}_X`;
        }
      }
      return ad;
    });
    if (ads) {
      config.ads = ads;
    }
  }
  return config;
};

type Config = {
  ads: any[];
};

const applyFilter = (config: Config, predicate: (ad: any) => boolean) => {
  const filteredAds = config.ads.filter(predicate);
  return { ...config, ads: filteredAds };
};

const filter = (config: any, isNative = false) => {
  config.ads =
    isDebugAds() || config.enableWallpaperAds === 'true'
      ? config.ads
      : config.ads.filter((ad: any) => ad.placement !== 'top_ad');
  const nativePredicate = isNative ? isNativeAd : isNotNativeAd;
  const filters = [nativePredicate, adHasExistingContainer];
  return filters.reduce((updatedConfig, filter) => applyFilter(updatedConfig, filter), config);
};

const adConfig = {
  get: getConfig,
  getModifiedConfig: getModifiedConfig,
  addExtraPlacements,
  filter: filter,
};

export default adConfig;
