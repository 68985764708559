import log from '../helpers/log';
import { capitalizeFirst, stripDashAndAfter, isEmpty } from '../helpers/utils';

const patternRegularSubdomain = /^(?:(?!www).)*$/m;

export const isOcelotSite = (): boolean => window.isMainSite;

const patternSubdomain = /^(\w+\.\w+\.\w+)$/m;
/**
 * Check if the current location.host is a regular (www) subdomain
 * or a blog
 * @return {boolean}
 */
export const hostIsSpecificSubdomain = (): boolean =>
  patternSubdomain.test(window.location.host) && patternRegularSubdomain.test(window.location.host);

const hasDfpId = (devSite: string): boolean => {
  if (window?.location?.search) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has('dfp_id') && urlParams.get('dfp_id') === devSite;
  }
  return false;
};

const devSites = ['labdevs.com', 'localhost', 'ocelot-', 'www-dev'];
const dfpIdSite = 'adtech';

const isDevSite = devSites.some((site) => window?.location?.hostname?.includes(site)) || hasDfpId(dfpIdSite);

export const getSiteFromLocation = (): string => {
  let siteName = '';

/////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////
///////////

  // Set siteName to dfp_id "adtech" if set on querystring
  if (hasDfpId(dfpIdSite)) {
    siteName = dfpIdSite;
  }

  // if dev host's as residence--www-calico-fredrik.labdevs.com or ocelot localhost
  if (isEmpty(siteName)) {
    const labPattern = /(\w{3,})(?=--www-calico-(\w{3,})\.labdevs\.com)/;
    const labMatch = window.location.host.match(labPattern);
    if (labMatch && labMatch[0]) {
      siteName = labMatch[0];
    } else if (isDevSite && window.siteName !== '') {
      siteName = window.siteName;
    }
  }

  if (isEmpty(siteName)) {
    const host = window.location.host.replace(/[www]{3}\./, '');
    const pattern = /(\w{4,})\./;
    const match = host.match(pattern);
    siteName = match && match[1] ? match[1] : '';
  }

  if (!siteName) {
    throw new Error(`Missing DEV_SITE or domain pattern in ${window.location.host}`);
  }
  log('siteName', siteName);
  return siteName;
};

const dotComHosts = ['mabra'];
const aliasHosts = ['residencemagazine'];

const resolveExceptionSiteName = (name: string): string => aliasHosts.find((site) => site.includes(name)) || name;

const resolveExceptionTopDomain = (site: string): string =>
  dotComHosts.some((host) => site.includes(host)) ? 'com' : 'se';

export const getHostNameFromSite = (name: string): string =>
  !isEmpty(name) ? `${resolveExceptionSiteName(name)}.${resolveExceptionTopDomain(name)}` : '';

export const isSubDomain = (hostname: string): boolean => {
  const hostParts = typeof hostname === 'string' ? hostname.split('.') : [];
  return hostParts.length > 2;
};

export const resolveDevSiteName = (dfpId: string): string => {
  const siteName = isSubDomain(dfpId) ? dfpId : `${process.env.DEV_SITE}`;
  if (isEmpty(siteName)) {
    throw new Error(`Missing DEV_SITE`);
  }
  return siteName;
};

export const getHostnameFromLocation = (): string => {
  let domain = '';

/////////////////
////////////////////////////////////////////////////////////////
///////////

  if (isEmpty(domain)) {
    domain = isDevSite ? getHostNameFromSite(getSiteName()) : window.location.hostname;
  }
  return domain;
};

export const getDomainFromHostName = (hostname: string): string => {
  let domainTld = '';
  if (typeof hostname === 'string') {
    const [, domain, topLevelDomain] = hostname.split('.');
    domainTld = domain && topLevelDomain ? `${domain}.${topLevelDomain}` : hostname;
  }
  return domainTld;
};

/**
 * @readonly
 * @type {string}
 */
export const getSiteName = getSiteFromLocation;

/**
 * @readonly
 * @type {string}
 */
export const getHostName = getHostnameFromLocation;


export const getSubdomainAdwatchName = (): string => {
  const components = window.location.host.split('.');
  if (components.length !== 3) {
    throw Error(`Unexpected length of location.host`);
  }
  const [subdomain, domain] = components;
  const capitalizedDomain = capitalizeFirst(domain);
  return `${subdomain}${capitalizedDomain}`;
};
export const alterSubdomain = (hostname: string): string => {
  let host = hostname;

  if (typeof host === 'string') {
    // Modify main sites
    host = host.replace(/((^www-live.)|(^www-dev.)|(^golive.)|(^www.))+/, '');

    let [subDomain, domain, topLevelDomain] = host.split('.');

    if (domain && topLevelDomain) {
      if (window.isMainSite) {
        host = `${domain}.${topLevelDomain}`;
      } else if (subDomain && host.split('.').length > 2) {
        // Modify blogs: Remove -stage, -test, -approval etc from subdomain
        subDomain = stripDashAndAfter(subDomain);
        host = `${subDomain}.${domain}.${topLevelDomain}`;
      }
    }
  }
  return host;
};

export const setDfpSection = (section: string | undefined): string => {
  let dfpSection = 'general';

  if (section) {
    dfpSection = section;
  } else {
    if (location.pathname.split('/')[1] !== '' && location.hostname.split('.')[0] == 'www') {
      dfpSection = location.pathname.split('/')[1];
    } else if (location.hostname.split('.')[0] !== 'www') {
      dfpSection = location.hostname.split('.')[0];
    }
  }
  return dfpSection;
}

export const setDfpId = (configDfpId: string): string => {
  let dfpId = configDfpId;
  const urlParams = new URLSearchParams(location.search);
  if (urlParams.has('dfp_id')) {
    dfpId = urlParams.get('dfp_id') || configDfpId;
  }
  return dfpId;
}

export const resolveAdUnitPathDfpId = (dfpId: string) => {
  let resolveId = dfpId;
  const isSub = isSubDomain(dfpId);

  if (dfpId === 'global' || isSub) {
    let hostname;
///////////////////

///////////////////////////////////////////////
/////////////////////////////////////////////

////////////

    hostname = window.location.host;

/////////////

    resolveId = getDomainFromHostName(hostname);
  }
  return resolveId;
};

